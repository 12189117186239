import './App.css';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function App() {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
          <a class="navbar-brand js-scroll-trigger" href="#page-top">
              <span class="d-block d-lg-none">Space Savers</span>
              <span class="d-none d-lg-block"><img class="img-fluid img-profile rounded-circle mx-auto mb-2" src="/img/1386190.jpg" alt="..." /></span>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
              <ul class="navbar-nav">
                  <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
                  <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">Services</a></li>
                  <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#workers">Meet the Organizer</a></li>
                  <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#photos">Photos</a></li>
                  <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#contact">Contact</a></li>
              </ul>
          </div>
      </nav>
      <Container fluid>
        <section class="resume-section" id="about">
          <div class="resume-section-content">
              <h1 class="mb-0">
                  Space
                  <span class="text-primary">Savers</span>
              </h1>
              <div class="subheading mb-5">
                  Declutter.  Organize.
              </div>

              <p  class="lead mb-5">              
              Do you know someone buried in treasures? Do you feel overwhelmed by your space? Set up a 
              free one hour consultation today! &nbsp;
              &mdash; Affordable.&nbsp; Sensitive.&nbsp; &mdash; &nbsp;
              
              You decide what goes or stays. I haul away and organize.
              </p>             
              
              <p  class="lead mb-5">
              I bring forth the vision you have for the space. I keep, organize and
              orderly display all important and special items, only removing items that
              have accumulated and don&rsquo;t belong.
              </p>

              <p class="lead mb-5">
              No job is too big or too small: I have experience organizing entire public
              schools, all the way down to my own entryway shoe rack. Each space matters. 
              Each space affects your mental state.
              </p>

              
          </div>
        </section>

        <section class="resume-section" id="services">
          <div class="resume-section-content">
              <div class="subheading mb-5">
                  Services
              </div>
              

              <ul style={{marginBottom:60}}>
                <li>Help identifying items that can be decluttered, and where to take them</li>

                <li>Help identifying important items to keep, and how to organize them in your space</li>

                <li>Help hauling away items with truck, and help organizing remaining items with bins</li>

                <li>Help with feelings of overwhelm, stuck in the process, or shame of belongings. </li>
              </ul>

              <p class="lead mb-5">
              My professional training is specific to working with clients that suffer
              from personal issues, and I emphasize personal connectedness and
              discreteness in our interactions. I do not wish to rush the process, or to
              push you further than you are ready to go. I wish to support everyone in
              making lasting systems that work for your space and needs.
              </p>
              
              <div style={{marginTop:15,marginBottom:30}}>
              <iframe src="https://player.vimeo.com/video/827062161?h=ea2939f99f" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>              
              </div>
              
              <strong>Rates</strong>
                <br />
              <p>I am availale on a sliding scale for up to 6-hour blocks. 
              Hauling/Dumping/Donating  is included in my hourly rate.              
              </p><p>Please <a href="mailto:space0savers@gmail.com">contact me to arrange work</a>!</p>
              
              
              <p><strong>How to pay:</strong> &nbsp; 
              Payment is collected at the end of each session. I accept credit cards, cash, Cash App or Venmo. 
              </p><br />
              <img src="/img/cash-app.png" style={{width:300,height:'auto'}} />
              <img src="/img/venmo.png" style={{width:300,height:'auto',marginLeft:30}} />
              
          </div>
        </section>
        
        <section class="resume-section" id="workers">
          <div class="resume-section-content">
              <div class="subheading mb-5">
                  Who I Am
              </div>
              
              <Card style={{ width: '100%' }}>
                <Card.Body>
                  <Card.Title>Laura Graves</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Owner</Card.Subtitle>
                  <Card.Text>
                  
                  <img class="float-start img-fluid img-profile rounded-circle" src="/img/IMG_2936.jpg" style={{width:"80px",maxWidth:"80px",height:"auto",marginRight:"10px"}} alt="..." />
                  
                  <p>
                  What sets Laura apart from other organizers is her dedication to working with clients suffering from chronic disorganization. With a background in psychology, she is not focused on making areas simply pretty. The motivation is to create systems that actually work for each person, while addressing underlying reasons and habits that contribute to chronic disorganization. Professional, patient, prepared, and eager to help, Laura will bring her knowledge and experience of tricks of the trade to each space. Driven to find solutions that work for each individual, Laura will go the extra mile with each person to research the best practices. Laura specializes in decluttering large and small spaces; knowing how to identify items to keep or go; and how to haul and donate items sustainability.&nbsp;
                  </p>

                  <p>
                  Laura continues her education and professional development with the Institute for Challenging Disorganization, an international P.O. organization. Now beginning her second level of certifications, Laura is benefiting from the community of organizers and psychologists that support each other in this work. She regularly attends classes and conferences, as well as local networking and support meetings, and keeps up with the newly published works on the subject. With a noticeable rise in the numbers of clients diagnosed with Hoarding Disorder and ADHD, Laura feels that this work is now more important than ever.&nbsp;&nbsp;
                  </p>

                  <p>
                  Above all, Laura understands that it is an immense honor to be trusted, invited into your space, and to listen closely to what your vision, hopes and needs are. She does not want to impose her vision of what the space should be; instead is solely focused on making each space reflect the owners wishes fully. With her kindness, understanding and personal touch, Laura stands out as a friendly hand to reach for when stuck in the 'clutter' weeds.
                  </p>

                  
                  <footer>
                    <img src="/img/GravesL-Level-I-Foundation-Certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL_Level-I-AGE-certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL-Level-I-HIC-certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL-Level-I-INT-certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL-Level-I-TMP-certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL-Level-MGMT-certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL_Level-I-LT-certificate.png" style={{width:400,height:'auto'}} />
                    <img src="/img/GravesL-Level-SLT-certificate.png" style={{width:400,height:'auto'}} />
                  </footer>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              
              
          </div>
        </section>  


        <section class="resume-section" id="photos">
          <div class="resume-section-content">
              <div class="subheading mb-5">
                  Before &amp; After Photos
              </div>
              
              <Row style={{marginBottom:40}}>
                <Col xs={6}>
                  <Image src="/img/IMG_1665.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
                <Col xs={6}>
                  <Image src="/img/IMG_1666.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
              </Row>
              <Row style={{marginBottom:40}}>
                <Col xs={6}>
                  <Image src="/img/IMG_1667.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
                <Col xs={6}>
                  <Image src="/img/IMG_1668.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
              </Row>
              <Row style={{marginBottom:40}}>
                <Col xs={6}>
                  <Image src="/img/IMG_1669.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
                <Col xs={6}>
                  <Image src="/img/IMG_1670.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
              </Row>
              <Row style={{marginBottom:40}}>
                <Col xs={6}>
                  <Image src="/img/IMG_1671.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
                <Col xs={6}>
                  <Image src="/img/IMG_1672.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Image src="/img/IMG_1673.jpg" rounded style={{width:'100%',maxWidth:'100%'}} />
                </Col>
              </Row>               
              
          </div>
        </section>                

        
        <section class="resume-section" id="contact">
          <div class="resume-section-content">
              <div class="subheading mb-5">
                  Contact Me
              </div>
              
              <p>Email to set up your first free walk-through today!</p>
              
              <p>Email: <a href="mailto:space0savers@gmail.com">space0savers@gmail.com</a></p>
              
              
          </div>
        </section>                


      </Container>
    </>
  );
}

export default App;
